import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Button,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

const GeoLocationModal = (props) => {
  const { isOpen, onOpen, onClose } = props;

  const acceptTerms = () => {
    localStorage.setItem("consent", true);
    onClose();
  };

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size="lg"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Disclaimer:</ModalHeader>
          <ModalBody>
            <Text mb={2}>
              No representation or warranty is made concerning any aspect of the
              Razor Network, including its suitability, quality, availability,
              accessibility, accuracy or safety. As more fully explained in the
              Terms of Use (available{" "}
              <Link
                color="blue.600"
                href={`https://razor.network/tos.pdf`}
                isExternal
              >
                here
                <ExternalLinkIcon mx="2" />
              </Link>
              ), your access to and use of the Razor Network through this
              interface is entirely at your own risk and could lead to
              substantial losses. You take full responsibility for your use of
              the interface, and acknowledge that you use it on the basis of
              your own enquiry, without solicitation or inducement by
              Contributors (as defined in the Terms of Use).
            </Text>
            <Text>
              This interface is not available to residents of{" "}
              <b>
                {" "}
                Afghanistan, Belarus, Bosnia and Herzegovina, the Central
                African Republic, Cuba, the Democratic Republic of Congo, the
                Democratic People’s Republic of Korea (North Korea), Eritrea,
                Ethiopia, Guinea Bissau, Iraq, Iran, Libya, Lebanon, Russia,
                Somalia, Sudan, South Sudan, Syria, the USA, Uganda, Vanuatu,
                Yemen and Zimbabwe or any other jurisdiction in which accessing
                or using the Razor Network is prohibited (“Prohibited
                Jurisdictions”). In using this interface, you confirm that you
                are not located in, incorporated or otherwise established in, or
                a citizen or resident of, a Prohibited Jurisdiction.
              </b>
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={acceptTerms}>
              Accept
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default GeoLocationModal;
