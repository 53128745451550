export const RAZOR_MAINNET_ADDRESS =
  process.env.REACT_APP_RAZOR_MAINNET_ADDRESS ||
  "0x50de6856358cc35f3a9a57eaaa34bd4cb707d2cd";
export const RAZOR_SCHAIN_ADDRESS =
  process.env.REACT_APP_RAZOR_SCHAIN_ADDRESS ||
  "0xcbf70914Fae03B3acB91E953De60CfDAaCA8145f";
export const SCHAIN_NAME = "turbulent-unique-scheat";
export const ALCHEMY_MAINNET_RPC = process.env.REACT_APP_ALCHEMY_MAINNET_RPC;

export const razorSchain = {
  id: 278611351,
  name: "Razor Skale Chain",
  network: "turbulent-unique-scheat",
  iconBackground: "#fff",
  nativeCurrency: {
    decimals: 18,
    name: "Skale",
    symbol: "ETH",
  },
  rpcUrls: {
    default: "https://mainnet.skalenodes.com/v1/turbulent-unique-scheat",
  },
  blockExplorers: {
    default: {
      name: "Skalenodes",
      url: "https://turbulent-unique-scheat.explorer.mainnet.skalenodes.com/",
    },
  },
  testnet: true,
};

export const sChainTokenInfo = {
  name: "Razor Network",
  address: RAZOR_SCHAIN_ADDRESS,
  symbol: "RAZOR",
  decimals: 18,
  image: "https://s2.coinmarketcap.com/static/img/coins/64x64/8409.png",
};

export const mainnetTokenInfo = {
  name: "Razor Network",
  address: RAZOR_MAINNET_ADDRESS,
  symbol: "RAZOR",
  decimals: 18,
  image: "https://s2.coinmarketcap.com/static/img/coins/64x64/8409.png",
};
