import { Accordion, AccordionButton, AccordionItem, AccordionIcon, AccordionPanel, Code, Text, Box, Container } from "@chakra-ui/react";

export const handleRpcError = (currentChain, error) => {
  return (
        <Accordion mx={0}>
            <AccordionItem mt="4" borderColor="transparent">
                <Text>
                <AccordionButton>
                    <Box flex="1" textAlign="left">
                        Transaction Failed on {currentChain?.name} with code: {error.code}
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                </Text>
                <AccordionPanel pb={4} overflowX="scroll">
                    <Code w="auto" >
                        {error.message}
                    </Code>
                </AccordionPanel>      
            </AccordionItem>
        </Accordion>
  );
};
