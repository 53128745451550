import {
  Badge,
  Button,
  Container,
  Flex,
  Input,
  Text,
  useToast,
  Tag,
  Tooltip,
  useColorMode,
  useInterval,
  useDisclosure,
  Code,
  Link,
  Alert,
  AlertDescription,
  AlertIcon,
} from "@chakra-ui/react";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import {
  chain,
  useAccount,
  useContract,
  useNetwork,
  useSigner,
  useSwitchNetwork,
} from "wagmi";
import { AddIcon, ExternalLinkIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import MainnetABI from "../abis/mainnetABI.json";
import RAZORABI from "../abis/RAZOR.json";
import RazorSchainABI from "../abis/RazorSchain.json";
import SchainABI from "../abis/schainABI.json";
import {
  RAZOR_MAINNET_ADDRESS,
  RAZOR_SCHAIN_ADDRESS,
  SCHAIN_NAME,
  razorSchain,
  sChainTokenInfo,
  mainnetTokenInfo,
  ALCHEMY_MAINNET_RPC,
} from "../utils/constants";
import { getTxLinkComponent } from "./shared/feedback";
import Steps from "./Steps";
import { handleRpcError } from "./shared/errorHandler";
import GeoLocationModal from "./shared/GeoLocationModal";

const Bridge = () => {
  const [bridgeAmount, setBridgeAmount] = useState(0);
  const [rechargeAmount, setRechargeAmount] = useState(0);
  const [minRechargeAmount, setMinRechargeAmount] = useState(0);
  const [isSwapLoading, setIsSwapLoading] = useState(false);
  const [isRechargeLoading, setIsRechargeLoading] = useState(false);
  const [isWithdrawLoading, setIsWithdrawLoading] = useState(false);
  const [isCommunityPoolLoading, setIsCommunityPoolLoading] = useState(false);
  const [razorBalance, setRazorBalance] = useState(0);
  const [communityPoolBalance, setCommunityPoolBalance] = useState(0);
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { data, address } = useAccount();
  const { data: signer } = useSigner();
  const { chain: currentChain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();
  const consent = localStorage.getItem("consent");

  const RazorContract = useContract({
    addressOrName: RAZOR_MAINNET_ADDRESS,
    contractInterface: RAZORABI,
    signerOrProvider: signer,
  });
  const RazorSchainContract = useContract({
    addressOrName: RAZOR_SCHAIN_ADDRESS,
    contractInterface: RazorSchainABI,
    signerOrProvider: signer,
  });

  const CommunityPoolContract = useContract({
    addressOrName: MainnetABI.community_pool_address,
    contractInterface: MainnetABI.community_pool_abi,
    signerOrProvider:
      currentChain?.id === chain.mainnet.id
        ? signer
        : new ethers.providers.AlchemyProvider("mainnet", ALCHEMY_MAINNET_RPC),
  });

  useEffect(() => {
    !consent && onOpen();
  }, []);

  useEffect(() => {
    setBridgeAmount(0);
    if (
      signer &&
      currentChain &&
      RazorContract.provider &&
      RazorSchainContract.provider
    ) {
      console.log(`ChainId: ${currentChain.id}`);
      fetchMinDepositAmount();
      fetchCommunityPoolBalance();
      if (currentChain?.id === chain.mainnet.id) {
        fetchBalance(RazorContract);
        // * FETCH COMMUNITY POOL BALANCE ONLY ON MAINNET
      } else if (currentChain?.id === razorSchain.id) {
        fetchBalance(RazorSchainContract);
      }
    }
  }, [currentChain?.id, signer, RazorContract, RazorSchainContract]);

  useInterval(() => {
    fetchRazorBalanceInterval();
  }, 60000);

  const fetchRazorBalanceInterval = () => {
    if (signer && RazorContract && RazorSchainContract) {
      if (currentChain?.id === chain.mainnet.id) {
        fetchBalance(RazorContract);
      } else if (currentChain?.id === razorSchain.id) {
        fetchBalance(RazorSchainContract);
      }
    }
  };

  const fetchCommunityPoolBalance = async () => {
    setIsCommunityPoolLoading(true);
    const communityPoolBalance = await CommunityPoolContract.getBalance(
      address,
      SCHAIN_NAME
    );
    console.log(
      `Community pool balance: ${ethers.utils.formatEther(
        communityPoolBalance
      )}`
    );
    setCommunityPoolBalance(ethers.utils.formatEther(communityPoolBalance));
    setIsCommunityPoolLoading(false);
  };

  const fetchBalance = async (contract) => {
    const balance = await contract.balanceOf(address);
    console.log(`Current chain: ${currentChain.name}`);
    console.log(`Balance: ${ethers.utils.formatEther(balance)}`);
    setRazorBalance(ethers.utils.formatEther(balance));
  };

  const fetchMinDepositAmount = async () => {
    const provider =
      currentChain?.id === chain.mainnet.id
        ? ethers.getDefaultProvider()
        : new ethers.providers.AlchemyProvider("mainnet", ALCHEMY_MAINNET_RPC);
    const gasPrice = await provider.getGasPrice();
    const minAmountRequired = ethers.utils.formatUnits(
      gasPrice.mul(ethers.BigNumber.from("1000000")).mul("2")
    );
    setMinRechargeAmount(minAmountRequired);
  };

  const triggerToast = (title, status = "success", description = null) => {
    toast({
      title,
      description,
      status,
      isClosable: true,
      duration: 5000,
    });
  };

  const approve = async (contract, spender, approveAmount) => {
    try {
      const amount = ethers.utils.parseEther(approveAmount);
      const tx = await contract.approve(spender, amount);
      console.log(`Sending tx to approve razor tokens`);
      console.log(tx);
      await tx.wait();
      triggerToast("Approve tx successfull!");
    } catch (error) {
      console.log("Error occured while approving");
    }
  };

  const transferMainnetToSchain = async () => {
    try {
      const DepositBox = new ethers.Contract(
        MainnetABI.deposit_box_erc20_address,
        MainnetABI.deposit_box_erc20_abi,
        signer
      );
      const tx = await DepositBox.depositERC20(
        SCHAIN_NAME,
        RAZOR_MAINNET_ADDRESS,
        ethers.utils.parseEther(bridgeAmount)
      );

      console.log(`Sending tx to bridge tokens from Ethereum to Razor Schain`);
      console.log(tx);
      await tx.wait();
      triggerToast(
        "Bridge tokens tx successful!",
        "success",
        getTxLinkComponent(currentChain, tx.hash)
      );
    } catch (error) {
      console.log(
        "Error occured while bridging tokens from Ethereum to Razor Schain"
      );
      console.log(error);
      triggerToast("", "error", handleRpcError(currentChain, error));
    }
  };

  const isAccountActive = async () => {
    const contract = new ethers.Contract(
      SchainABI.community_locker_address,
      SchainABI.community_locker_abi,
      signer
    );
    const isActive = await contract.activeUsers(address);
    return isActive;
  };

  const transferSchainToMainnet = async () => {
    try {
      const SchainTokenManager = new ethers.Contract(
        SchainABI.token_manager_erc20_address,
        SchainABI.token_manager_erc20_abi,
        signer
      );
      const tx = await SchainTokenManager.exitToMainERC20(
        RAZOR_MAINNET_ADDRESS,
        ethers.utils.parseEther(bridgeAmount)
      );

      console.log(
        `Sending transaction to bridge tokens from Razor Schain to Ethereum`
      );
      console.log(tx);
      await tx.wait();

      triggerToast(
        "Bridge tokens transaction successful!",
        "success",
        getTxLinkComponent(currentChain, tx.hash)
      );
    } catch (error) {
      console.log(
        "Error occurred while bridging tokens from Razor Schain to Ethereum"
      );
      console.log(error);
      triggerToast("", "error", handleRpcError(currentChain, error));
    }
  };

  const addRazorToken = async () => {
    try {
      const ethereum = window.ethereum;
      //ADD RAZOR TOKEN ON SCHAIN
      if (currentChain?.id === razorSchain.id) {
        const addedSchainToken = await ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20", // Initially only supports ERC20, but eventually more!
            options: sChainTokenInfo,
          },
        });
        console.log("addedSchain", addedSchainToken);
      } else if (currentChain?.id === chain.mainnet.id) {
        //ADD RAZOR TOKEN ON MAINNET
        const addedMainnetToken = await ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20", // Initially only supports ERC20, but eventually more!
            options: mainnetTokenInfo,
          },
        });
        console.log("addedSchain", addedMainnetToken);
      }
    } catch (error) {
      console.log("Error adding Razor token", error);
    }
  };

  const isSufficientBalance = () => {
    const balance = ethers.utils.parseEther(razorBalance);
    const inputBalance = ethers.utils.parseEther(String(bridgeAmount));
    if (!balance.gte(inputBalance)) {
      triggerToast("Insufficient RAZOR balance", "error");
      return false;
    }
    return true;
  };

  const bridge = async () => {
    if (signer && isSufficientBalance()) {
      setIsSwapLoading(true);

      // * BRIDGE FROM MAINNET TO SCHAIN
      if (currentChain?.id === chain.mainnet.id) {
        await approve(
          RazorContract,
          MainnetABI.deposit_box_erc20_address,
          bridgeAmount
        );
        await transferMainnetToSchain();
        fetchBalance(RazorContract);
      } else {
        // * BRIDGE FROM SCHAIN TO MAINNET
        // * Check if the user wallet is active
        const isActive = await isAccountActive();
        // Check if the users community wallet balance is atleast 70% of the minRechargeAmount
        const isCommunityPoolBalanceLow =
          communityPoolBalance < minRechargeAmount * 0.95;
        if (isActive && !isCommunityPoolBalanceLow) {
          await approve(
            RazorSchainContract,
            SchainABI.token_manager_erc20_address,
            bridgeAmount
          );
          await transferSchainToMainnet();
          fetchBalance(RazorSchainContract);
        } else {
          triggerToast(
            "User's Community Pool balance is not enough!",
            "error",
            `Add atleast ${
              minRechargeAmount * 0.95
            } ETH to the Community Pool to bridge to Mainnet.`
          );
        }
      }
    }
    setBridgeAmount(0);
    setIsSwapLoading(false);
  };

  const callRechargeUserWallet = async () => {
    setIsRechargeLoading(true);
    let tx;
    if (Number(rechargeAmount) + Number(communityPoolBalance) <= 1) {
      try {
        const communityPool = new ethers.Contract(
          MainnetABI.community_pool_address,
          MainnetABI.community_pool_abi,
          signer
        );
        tx = await communityPool.rechargeUserWallet(SCHAIN_NAME, address, {
          value: ethers.utils.parseEther(rechargeAmount),
        });
        console.log(`Sending tx to Community Pool on Ethereum`);
        console.log(tx);
        await tx.wait();
        triggerToast(
          "ETH added to Community Pool!",
          "success",
          getTxLinkComponent(currentChain, tx.hash)
        );
        triggerToast(
          "You can now bridge from Razor Schain to Ethereum.",
          "success"
        );
        setRechargeAmount(0);
      } catch (error) {
        console.log("Error occurred while recharging user wallet!");
        console.log(error);
        triggerToast("", "error", handleRpcError(currentChain, error));
      } finally {
        setIsRechargeLoading(false);
        fetchCommunityPoolBalance();
      }
    } else {
      setIsRechargeLoading(false);
      triggerToast(
        "A maximum of 1 ETH can be deposited into the Community Pool!",
        "error",
        "The pool deposit is capped, since it is only used for gas on Ethereum."
      );
    }
  };

  const withdrawUserBalance = async () => {
    setIsWithdrawLoading(true);
    let tx;
    try {
      const communityPool = new ethers.Contract(
        MainnetABI.community_pool_address,
        MainnetABI.community_pool_abi,
        signer
      );

      tx = await communityPool.withdrawFunds(
        SCHAIN_NAME,
        ethers.utils.parseEther(rechargeAmount),
        {
          gasLimit: 150000,
        }
      );
      console.log(`Sending tx to withdraw gas balance`);
      console.log(tx);
      await tx.wait();

      triggerToast(
        "Withdrawn funds from Community pool",
        "success",
        getTxLinkComponent(currentChain, tx.hash)
      );
      setRechargeAmount(0);
    } catch (error) {
      console.log("Error occurred while withdrawing funds");
      console.log(error);
      triggerToast("", "error", handleRpcError(currentChain, error));
    } finally {
      setIsWithdrawLoading(false);
      fetchCommunityPoolBalance();
    }
  };

  return (
    <>
      <Container mt={4}>
        <Flex justifyContent="flex-end" alignItems="center" mt={4} mb={2}>
          <Badge colorScheme="teal" fontSize="lg">
            {razorBalance || 0} RAZOR
          </Badge>
          <Tooltip
            label={`Add the Razor token on ${
              currentChain?.id === chain.mainnet.id
                ? "Ethereum"
                : "Razor Schain"
            }`}
            aria-label="A tooltip"
          >
            <Tag
              ml={4}
              colorScheme="purple"
              cursor="pointer"
              onClick={addRazorToken}
            >
              <AddIcon w={3} h={3} />
            </Tag>
          </Tooltip>
        </Flex>

        <Container
          px={0}
          my={10}
          boxShadow={`${colorMode === "light" ? "md" : "dark-lg"}`}
          padding="24px"
          borderRadius="4px"
        >
          <Text my="5">
            <Text fontSize="xl" fontWeight="bold">
              Bridge to{" "}
              {currentChain?.id === chain.mainnet.id ? "SChain" : "Ethereum"}
            </Text>
          </Text>
          <Text mb={2}>Set Bridge Amount</Text>
          <Flex>
            <Input
              type="number"
              placeholder="Enter amount"
              flex={7}
              value={bridgeAmount}
              onChange={(e) => setBridgeAmount(e.target.value)}
            />
          </Flex>
          <Button
            mt={4}
            w="full"
            colorScheme="blue"
            disabled={
              isSwapLoading ||
              parseFloat(bridgeAmount) <= 0 ||
              bridgeAmount === ""
            }
            onClick={bridge}
            isLoading={isSwapLoading}
          >
            Bridge
          </Button>
          {currentChain?.id === chain.mainnet.id ? (
            <Text pt="4">
              Bridge Contract Address on Ethereum:{" "}
              <Code>0x8fB1A35bB6fB9c47Fb5065BE5062cB8dC1687669</Code>
              <Link
                fontSize="sm"
                href={`https://etherscan.io/address/0x8fb1a35bb6fb9c47fb5065be5062cb8dc1687669`}
                isExternal
              >
                <ExternalLinkIcon mx="2" />
              </Link>
            </Text>
          ) : (
            <Text pt="4">
              Bridge Contract Address on Razor Schain:{" "}
              <Code>0xD2aAA00500000000000000000000000000000000</Code>
              <Link
                fontSize="sm"
                href={`https://turbulent-unique-scheat.explorer.mainnet.skalenodes.com/address/0xD2aAA00500000000000000000000000000000000/transactions`}
                isExternal
              >
                <ExternalLinkIcon mx="2" />
              </Link>
            </Text>
          )}
          {currentChain?.id !== chain.mainnet.id && (
            <>
              <Text mt="4">
                Community Pool balance:<b>{communityPoolBalance} ETH</b>
              </Text>
              {minRechargeAmount > communityPoolBalance && (
                <Alert status="warning" mt="4" borderRadius="4px">
                  <AlertIcon />
                  <AlertDescription cursor="pointer">
                    Community Pool balance is <b>low</b>, recharge the wallet
                    before using the bridge. Low balance will result in a delay
                    (8 hours or more depending on current gas prices).{" "}
                    <i onClick={() => switchNetwork(chain.mainnet.id)}>
                      <u>Switch to Ethereum</u>
                    </i>
                  </AlertDescription>
                </Alert>
              )}
            </>
          )}
          <Steps minRechargeAmount={minRechargeAmount} />
          <Text fontSize="xl" fontWeight="bold" as="em" mt={10}>
            Note: Bridge transfer typically takes 3-5 minutes
          </Text>
        </Container>
        {currentChain?.id === chain.mainnet.id && (
          <Container
            px={0}
            my={10}
            boxShadow={`${colorMode === "light" ? "md" : "dark-lg"}`}
            padding="24px"
            borderRadius="4px"
          >
            <Text fontSize="xl" mb={5} fontWeight="bold">
              Recharge Community Pool
            </Text>
            <Text mb={2}>Add Recharge Amount</Text>
            <Flex>
              <Input
                type="number"
                placeholder="Enter amount"
                flex={7}
                value={rechargeAmount}
                onChange={(e) => setRechargeAmount(e.target.value)}
              />
            </Flex>
            <Flex flexDirection="column">
              {currentChain?.id === chain.mainnet.id && (
                <Text pt="4">
                  Community Pool Contract Address on Ethereum:{" "}
                  <Code>0x588801cA36558310D91234aFC2511502282b1621</Code>
                  <Link
                    fontSize="sm"
                    href={`https://etherscan.io/address/0x588801cA36558310D91234aFC2511502282b1621`}
                    isExternal
                  >
                    <ExternalLinkIcon mx="2" />
                  </Link>
                </Text>
              )}
              <Text mt="2" alignItems="center">
                Min. deposit required: {minRechargeAmount} ETH{" "}
                <Tooltip label="This is to make sure the transaction on Ethereum Mainnet is successful. Unused gas can be reclaimed by the user.">
                  <InfoOutlineIcon />
                </Tooltip>
              </Text>
              <Text colorScheme="teal" fontSize="md">
                Your Community Pool Balance: {communityPoolBalance} ETH
              </Text>
            </Flex>

            <Flex>
              <Button
                mt={4}
                w="full"
                colorScheme="blue"
                disabled={
                  isRechargeLoading ||
                  parseFloat(rechargeAmount) <= 0 ||
                  rechargeAmount === ""
                }
                onClick={callRechargeUserWallet}
                isLoading={isRechargeLoading || isCommunityPoolLoading}
              >
                Recharge
              </Button>
              <Button
                mt={4}
                ml="4"
                w="full"
                colorScheme="red"
                disabled={
                  isWithdrawLoading ||
                  parseFloat(rechargeAmount) <= 0 ||
                  rechargeAmount === ""
                }
                onClick={withdrawUserBalance}
                isLoading={isWithdrawLoading || isCommunityPoolLoading}
              >
                Withdraw
              </Button>
            </Flex>
          </Container>
        )}
        <GeoLocationModal isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
      </Container>
    </>
  );
};

export default Bridge;
