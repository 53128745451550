import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Link } from "@chakra-ui/react";

export const getTxLinkComponent = (currentChain, txHash) => {
  return (
    <Link
      href={`${currentChain.blockExplorers.default.url}/tx/${txHash}`}
      isExternal
    >
      Check out transaction on Explorer
      <ExternalLinkIcon mx="2" />
    </Link>
  );
};
