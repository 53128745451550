import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Container, Flex, Image, Link, Text } from "@chakra-ui/react";
import SkaleLogo from "../assets/skale_logo.png";
import RazorLogo from "../assets/icon.png";

const Footer = () => {
  return (
    <Container py={10} textAlign="center">
      <Flex justifyContent="space-around" mb={10}>
        <Text display="flex" alignItems="center">
          Powered by <Image mx="2" src={SkaleLogo} w="70px" h="40px" /> IMA
          Bridge
        </Text>
        <Text display="flex" alignItems="center">
          Frontend Interface developed by{" "}
          <Image src={RazorLogo} w="40px" h="40px" />
        </Text>
      </Flex>
      <Flex justifyContent="space-around">
        <Text fontSize="sm">© 2022 Razor Network</Text>
        <Link
          fontSize="sm"
          href={` https://docs.razor.network/docs/token-bridge/intro`}
          isExternal
        >
          Documentation
          <ExternalLinkIcon mx="2" />
        </Link>
        <Link fontSize="sm" href={`https://razor.network/tos.pdf`} isExternal>
          Terms of Service
          <ExternalLinkIcon mx="2" />
        </Link>
        <Link
          fontSize="sm"
          href="https://discord.com/invite/gM5XBy6"
          isExternal
        >
          Support
          <ExternalLinkIcon mx="2" />
        </Link>
      </Flex>
    </Container>
  );
};

export default Footer;
