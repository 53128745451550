import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { configureChains, createClient, WagmiConfig, chain } from "wagmi";
import { razorSchain } from "./utils/constants";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { publicProvider } from "wagmi/providers/public";
import * as Sentry from "@sentry/react";

import App from "./App";
// Rainbow kit styles
import "@rainbow-me/rainbowkit/styles.css";
import { BrowserTracing } from "@sentry/react";

const { chains, provider } = configureChains(
  [razorSchain, chain.mainnet],
  [
    jsonRpcProvider({
      rpc: (chain) => {
        if (chain.id !== razorSchain.id) return null;
        return {
          http: "https://mainnet.skalenodes.com/v1/turbulent-unique-scheat",
        };
      },
    }),
    publicProvider(),
  ],
  { pollingInterval: 10_000 }
);

const { connectors } = getDefaultWallets({
  appName: "Razor Token Bridge",
  chains,
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

Sentry.init({
  dsn: "https://52a978adc3a74fb3a350289c9e2816d6@o1070194.ingest.sentry.io/4505232827416576",

  // Capture Replay for 80% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new BrowserTracing(),
    new Sentry.Integrations.Breadcrumbs({ console: false }),
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: false,
      blockAllMedia: true,
    }),
  ],
  tracesSampleRate: 1.0,
  tracingOrigins: ["bridge.razorscan.io"],
  routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ChakraProvider>
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider chains={chains}>
          <App />
        </RainbowKitProvider>
      </WagmiConfig>
    </ChakraProvider>
  </React.StrictMode>
);
