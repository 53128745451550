import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
} from "@chakra-ui/react";
import { useNetwork, chain } from "wagmi";

const Steps = ({ minRechargeAmount }) => {
  const { chain: currentChain } = useNetwork();

  return (
    <Accordion allowToggle my={5}>
      <AccordionItem mt="4">
        <h1>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Steps to bridge tokens from{" "}
              {currentChain?.id === chain.mainnet.id
                ? "Ethereum to Schain"
                : "Schain to Ethereum"}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h1>
        <AccordionPanel pb={4}>
          {currentChain?.id === chain.mainnet.id ? (
            <>
              <Text>1. Approve Deposit Box</Text>
              <Text>2. Transfer RAZOR to Deposit Box (depositERC20)</Text>
            </>
          ) : (
            <>
              <Text>
                1. Recharge the Community Pool Wallet on <b>Ethereum</b>. All
                the unused gas can be fully reclaimed by the user. Current
                recommended recharge amount for the Community Pool is:{" "}
                <b> {minRechargeAmount} ETH</b>
              </Text>
              <Text>2. Approve tokens.</Text>
              <Text>3. Transfer tokens. </Text>
              <Text>
                <i>
                  Note: Switch Networks to <b>Ethereum</b> to recharge the
                  community pool <u>before</u> trying to bridge tokens from
                  Razor Schain to Ethereum.{" "}
                  <b>Use the same address to recharge the community pool.</b>
                </i>
              </Text>
            </>
          )}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default Steps;
