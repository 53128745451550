import { Center, Container, Flex, Heading, Text } from "@chakra-ui/react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { Image } from "@chakra-ui/react";

import Footer from "./components/Footer";
import ThemeToggle from "./components/ThemeToggle";
import RazorLogo from "./assets/icon.png";
import Bridge from "./components/Bridge";

function App() {
  return (
    <div className="App">
      <Flex justifyContent="flex-end" m={4}>
        <ThemeToggle />
        <ConnectButton />
      </Flex>
      <Center>
        <Image mr="2" src={RazorLogo} w="28" h="28" />
        <Heading>Razor Token Bridge</Heading>
      </Center>
      <Flex>
        <Bridge />
      </Flex>
      <Footer />
    </div>
  );
}

export default App;
